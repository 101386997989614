import React from 'react'
import useMarketSellerHook from './components/useMarketSeller.hook';
import MarketSellerCss from './components/MarketSeller.style';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedNumber, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { requestTOGetMarketProductSeller } from '../ListingPage.duck';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { NamedLink } from '../../../components';
import { createSlug } from '../../../util/urlHelpers';

const MarketSellerProductComp = (props) => {

    const { chooseOption, productsList, imagesArray, sellerRecord, setChooseOption } = useMarketSellerHook(props);

    console.log(props, 'propspropsprops')
    return (
        <MarketSellerCss>
            <div className='main_market_class'>
                <section className='tabSection'>
                    <div className='tab_button '>
                        <button className={classNames('button', { 'active': chooseOption === 'product' })} onClick={() => setChooseOption('product')}>Product's</button>
                    </div>
                    <div className='tab_button '>
                        <button className={classNames('button', { 'active': chooseOption === 'seller' })} onClick={() => setChooseOption('seller')}>Seller</button>
                    </div>
                </section>
                <section className='content_section'>
                    {chooseOption === "product" &&
                        <div className='productContainer'>
                            {_.map(productsList, ({ id, title, description, images, price }) => {
                                const slug = createSlug(title);

                                return (
                                    <NamedLink className="" name="ListingPage" params={{ id: id?.uuid, slug }}>

                                        <div className="cardSection" key={id?.uuid}>
                                            <div className="bxWrapper">
                                                <div className="imageDiv">
                                                    {images && images?.data?.length > 0 &&
                                                        <img src={_.find(imagesArray, (r) => r?.id?.uuid === images?.data[0]?.id?.uuid)?.attributes?.variants?.default?.url} />
                                                    }
                                                </div>
                                                <div className="">
                                                    <h4>{title}</h4>
                                                    <p>{description}</p>
                                                    <div className='price'>
                                                        <FormattedNumber value={price?.amount / 100} style="currency" currency={price?.currency} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NamedLink>
                                )
                            })}
                        </div>
                    }
                    {chooseOption === "seller" &&
                        <div className='sellerSection_section'>
                            {_.map(sellerRecord, (r) => (
                                <Link to={`/store-products/${r?.id?.uuid}`}>
                                    <div className='sellerDetail'>
                                        <div className='sellerName'>
                                            <p>{_.first(_.upperCase(r?.sellerData?.firstName))} {_.last(_.upperCase(r?.sellerData?.lastName))}</p>
                                        </div>
                                        <div className='sellerValues'>
                                            <div><p>Name: </p><p>{r?.sellerData?.firstName} {r?.sellerData?.lastName}</p></div>
                                            <div><p>Email: </p><p>{r?.sellerData?.email}</p></div>
                                            <div><p>Phone: </p><p>{r?.sellerData?.phoneNumber}</p></div>
                                            <div><p>Store: </p><p>{r?.sellerData?.storename}</p></div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    }
                </section>
            </div >
        </MarketSellerCss>
    )
}


const mapStateToProps = state => {
    // Topbar needs user info.
    const { currentUser } = state.user;
    const {
        storeProductsData,
    } = state.StoreProductsPage;
    return {
        currentUser,
        storeProductsData
    };
};

const mapDispatchToProps = dispatch => ({
    marketSellerData: (id) => dispatch(requestTOGetMarketProductSeller(id))
})

const MarketSellerProduct = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(MarketSellerProductComp);

export default MarketSellerProduct;