import _ from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { convertMoneyToNumber, formatMoney } from "../../../../util/currency";

const useMarketSellerHook = (props) => {

    const { marketSellerData, match } = props;
    console.log(props, 'storeProductsDatastoreProductsDatastoreProductsDatastoreProductsData')

    const [chooseOption, setChooseOption] = useState('product');
    const [productsList, setProductList] = useState([]);
    const [sellerRecord, setSellerRecord] = useState([]);
    const [imagesArray, setImageArray] = useState([]);

    const intl = useIntl();

    console.log(productsList, sellerRecord, 'productsListproductsListproductsListproductsList');
    useEffect(() => {
        if (match?.params?.id) {
            marketSellerData(match?.params?.id).then((res) => {
                const { productItems, sellersLists } = res
                const PRODUCTS = _.map(productItems?.data, (row) => {
                    const { price } = row?.attributes;

                    // const formattedPrice =
                    //     price
                    //         ? formatMoney(intl, price)
                    //         : price?.currency
                    //             ? price.currency
                    //             : null;

                    const PICKS_PRODUCTS = {
                        id: row?.id,
                        images: row?.relationships?.images,
                        description: row?.attributes.description,
                        title: row?.attributes.title,
                        status: row?.attributes.state,
                        price
                    }
                    return PICKS_PRODUCTS;
                })
                setProductList(PRODUCTS);
                setImageArray(productItems?.included);

                const SELLER_DATA = _.map(sellersLists?.data, (row) => {
                    const SELLER_LIST = {
                        id: row?.id,
                        author: row?.relationships?.author,
                        sellerData: row?.attributes?.publicData
                    }
                    return SELLER_LIST;
                })
                setSellerRecord(SELLER_DATA);
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [match?.path]);

    return {
        chooseOption, setChooseOption,
        productsList, sellerRecord, imagesArray
    }

}

export default useMarketSellerHook;