import React from 'react'
import { Heading } from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.css';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import styled from 'styled-components';

const SectionDetailsStyle = styled.div`
    h2 {
        margin-bottom: 8px;
    }
    p {
        margin: 0;
    }
    .details {
        margin-bottom: 16px;
    }
    .address_class {
        display: flex;
        align-items: center;
    }
    .title {
        width: 100%;
        max-width: 180px;
    }
`;

const COMMONCOMP = ({ label, values }) => (
    <div className='address_class'>
        <p className='title'>{label}: &nbsp;</p>
        <span>{values}</span>
    </div>
)

const SectionDetails = (props) => {
    const { className, rootClassName, publicData, currentUser } = props;
    const classes = classNames(rootClassName || css.sectionMap, className);

    const user = ensureCurrentUser(currentUser);

    return (
        <SectionDetailsStyle>
            <div className={classes}>
                <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
                    <FormattedMessage id="ListingPage.detailsTitle" />
                </Heading>
                <div className='details'>
                    <COMMONCOMP label='Name' values={`${currentUser.attributes.profile.displayName}`} />
                    {/* <COMMONCOMP label='E-mail' values={user?.attributes?.email} /> */}
                    <COMMONCOMP label='Address' values={publicData?.location?.address} />
                    <COMMONCOMP label='City' values={publicData?.city} />
                    <COMMONCOMP label='State' values={publicData?.state} />
                    <COMMONCOMP label='Country' values={publicData?.country} />
                    <COMMONCOMP label='ZipCode' values={publicData?.zipCode} />
                    <div className=''>
                        <COMMONCOMP label='Season OpenDate' values={publicData?.seasonOpenDate} />
                        <COMMONCOMP label='Season CloseDate' values={publicData?.seasonCloseDate} />
                    </div>
                </div>
            </div>
        </SectionDetailsStyle>
    )
}

export default SectionDetails
