import styled from "styled-components";

const MarketSellerCss = styled.div`
    margin-top: 30px;
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    .main_market_class {
        .tabSection {
            display: flex;
            gap: 10px;

            .tab_button  {
                width: 100%;
                max-width: 230px;

                button {
                    cursor: pointer;
                    width: 100%;
                    padding: 10px 10px;
                    font-size: 18px;
                    border: unset;

                    &.active {
                        border-bottom: 2px solid var(--marketplaceColorLight);
                        color: var(--marketplaceColorLight);        
                    }
                }
            }
        }

        .content_section {
            margin: 40px 0;

            .productContainer {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;

                a {
                    color: unset;
                    &:hover {
                        text-decoration: unset;
                        .cardSection { 
                            box-shadow: 0 0 9px 1px #035bce7a;
                        }
                    }
                }

                .cardSection {
                    width: 100%;
                    max-width: 220px;
                    min-width: 220px;
                    text-align: center;
                    min-height: 360px;
                    box-shadow: 0 0 7px 1px #dddd;
                    padding: 12px;
                    border-radius: 5px;

                    .price {
                        text-align: center;
                    }

                    .imageDiv {
                        width: 100%;
                        height: 180px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    
                }
            }

            .sellerSection_section {

                a {
                    color: unset;
                    &:hover {
                        text-decoration: unset;
                        .sellerDetail {
                            box-shadow: 0 0 9px 1px #035bce7a;
                        }
                    }
                }

                .sellerDetail {
                    display: flex;
                    box-shadow: 0 0 7px 1px #ddd;
                    width: 100%;
                    max-width: 370px;
                    gap: 15px;
                    padding: 8px;
                    align-items: center;
                    border-radius: 5px;

                    .sellerName {
                        p {
                            border: 1px solid;
                            padding: 6px;
                            border-radius: 30px;
                        }
                    }

                    .sellerValues {
                        div {
                            display: flex;
                            gap: 10px;

                            p:first-child {
                                min-width:60px;
                            }
                        }
                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                
            }
        }
    }

`;

export default MarketSellerCss;